<template>
  <div class="af-panel" v-if="visible">
    <div class="af-panel__text mb-4">
      <b-icon
        icon-pack="fa"
        icon="sad-tear"
        size="is-large"
        type="is-danger"
        class="mr-2"
      ></b-icon>

      <div>
        <p class="is-size-5 has-text-weight-bold mb-2">
          {{ $t("af:offers.non_eligible.title") }}
        </p>

        <span> {{ $t("af:offers.non_eligible.subtitle") }} </span>
      </div>
    </div>

    <div class="af-panel__actions">
      <b-button
        id="mortgageOffersNonEligibleBackToPrelim"
        type="is-danger"
        class="has-text-weight-medium"
        @click="backToPreliminary"
        >{{ $t("af:offers.non_eligible.button.back_to_preliminary") }}</b-button
      >

      <b-button
        v-if="cbrVisible"
        id="mortgageOffersNonEligibleCallMeBack"
        type="is-info"
        icon-left="plus-circle"
        class="has-text-weight-medium call-back-button"
        @click="openCallMeBackModal"
        >{{ $t("af:offers.non_eligible.call_me_back") }}</b-button
      >
    </div>
  </div>
</template>

<script>
import { monify } from "@/utils/util";
import { CALL_ME_BACK_TYPE, UI_CONFIGS } from "@/utils/const";
import eventBus, { EVENTS } from "@/utils/eventBus";
import { mapGetters } from "vuex";

export default {
  name: "NonEligible",
  data() {
    return {
      monify,
      visible: true,
    };
  },
  props: {
    mortgage: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      autoloadConfigValue: "config/getAutoloadConfigValue",
    }),
    cbrVisible() {
      return this.$route.meta.mortgage
        ? this.autoloadConfigValue(UI_CONFIGS.CALL_BACK_REQUEST_MORTGAGE) ===
            "true"
        : this.autoloadConfigValue(UI_CONFIGS.CALL_BACK_REQUEST) === "true";
    },
  },
  methods: {
    backToPreliminary() {
      this.$router.push(
        this.mortgage
          ? "/mortgage/preliminary-assessment"
          : "/preliminary-assessment"
      );
    },
    openCallMeBackModal() {
      eventBus.$emit(
        EVENTS.OPEN_CALL_ME_BACK_MODAL,
        CALL_ME_BACK_TYPE.NO_OFFER
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.af-panel {
  border-radius: 12px;
  padding: 20px;
  background: #fccbcb;
  border: 1px solid #f45252;

  .call-back-button {
    margin-left: 0;
    margin-top: 0.5rem;
  }

  &__text {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
  }

  &__actions {
    button,
    span {
      width: 100%;
    }
  }
}

@media only screen and (min-width: 769px) {
  .af-panel {
    padding: 32px 36px;

    .call-back-button {
      margin-left: 1rem;
      margin-top: 0;
    }

    &__actions {
      button,
      span {
        width: unset;
      }
    }
  }
}
</style>
